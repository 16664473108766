/** @format */
import React, { useRef } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Heading from "../components/common/heading";
import NewsLetters from "../components/common/newsletter";
import TextInput from "../components/common/input2";
import { useState } from "react";
import { Toast } from "../components/common/toast";
import Seo from "../components/common/SEO";
import emailjs from "@emailjs/browser";

export default function Contact({ data }) {
  const { sanityContactUs } = data;
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  });
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6dsqvb3",
        "template_derh4xn",
        form.current,
        "1TvAR0OSFYquDyxMk"
      )
      .then(
        () => {
          setFormData({
            ...formData,
            name: "",
            email: "",
            phone: "",
            address: "",
            message: "",
          });
          setShowToast(true);
          setInterval(() => {
            setShowToast(false);
          }, 2000);
        },
        (error) => {
          // console.log("---error===", error);
          setError(true);
          setInterval(() => {
            setError(false);
          }, 4000);
        }
      );
  };
  return (
    <Layout>
      <Seo
        title={sanityContactUs?.seo?.metaTitle}
        description={sanityContactUs?.seo?.metaDescription}
      />
      <div className="pt-[82px]">
        <div className="mix-container">
          <div className="flex flex-col items-center justify-center">
            <Heading
              title={sanityContactUs?.title}
              caption={sanityContactUs?.caption}
              paddingNeed={8}
            />
          </div>
          <div className="mt-[62px] grid grid-cols-1 md:grid-cols-12  px-4 md:px-0 md:gap-9">
            <div className="col-span-4 md:pl-4">
              <p className="text-[25px] mb-[11px] leading-[177.4%] text-black font-primary">
                Send us your MIX query
              </p>
              <div>
                <p className="text-sm">
                  Owner:
                  <br />
                  <a
                    className="hover:underline"
                    href="mailto:Danielle@MixByDanielle.com"
                  >
                    Danielle@MixByDanielle.com
                  </a>
                </p>
                <p className="mt-3 text-sm">
                  Party Operations:
                  <br />
                  <a
                    className="hover:underline"
                    href="mailto:Megan@MixByDanielle.com"
                  >
                    Megan@MixByDanielle.com
                  </a>
                </p>
              </div>
              {/* <div className="h-[20px]">
                {error && (
                  <div>
                    <h1 className="font-medium text-red-500">contact failed</h1>
                  </div>
                )}
              </div>
              <Toast show={showToast}>Contact form sent</Toast>
              <form onSubmit={sendEmail} ref={form}>
                <TextInput
                  name="from_name"
                  placeholder="Full Name"
                  type="text"
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                  value={formData.name}
                />
                <TextInput
                  name="from_email"
                  placeholder="Email"
                  type="email"
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                  value={formData.email}
                />
                <TextInput
                  name="from_phone"
                  placeholder="Phone Number"
                  type="number"
                  onChange={(e) => {
                    setFormData({ ...formData, phone: e.target.value });
                  }}
                  value={formData.phone}
                />
                <TextInput
                  name="from_address"
                  placeholder="Address"
                  type="text"
                  onChange={(e) => {
                    setFormData({ ...formData, address: e.target.value });
                  }}
                  value={formData.address}
                />
                <textarea
                  name="message"
                  className="outline-none border border-black bg-transparent px-4 py-[10px] text-[13px] leading-[177.4%] font-primary font-normal text-black placeholder-black w-full"
                  placeholder="Message here"
                  rows={4}
                  onChange={(e) => {
                    setFormData({ ...formData, message: e.target.value });
                  }}
                  value={formData.message}
                ></textarea>

                <input type="submit" value="Send" className="pointer-cursor" />
              </form> */}
            </div>
            <div className="col-span-8 mt-5 md:mt-0">
              {/* <p className="text-[25px] mb-[11px] leading-[177.4%] text-black font-primary">
                Find Us
              </p> */}
              <div className="mt-5">
                <div className="relative">
                  <GatsbyImage
                    image={sanityContactUs?.thumbnail?.asset?.gatsbyImageData}
                    alt="moment-image"
                  />
                  {/* <div className="absolute bg-black/40 px-2 sm:px-[26px]  pt-2 pb-2 sm:pt-[17px] sm:pb-[19px] w-[180px] sm:w-[270px] left-7 bottom-7 sm:left-10 sm:bottom-10">
                    <p className="text-[15px] leading-[177.4%] text-white font-primary">
                      Contact Us
                    </p>
                    <p className="text-[13px] leading-[177.4%] text-white font-primary">
                      +912 33545 646
                    </p>
                    <p className="text-[13px] leading-[177.4%] text-white font-primary">
                      conatct@mix.com
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[106px]">
          <NewsLetters small={true} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityContactUs {
      title
      caption
      thumbnail {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;
